import React, {useState, useEffect} from 'react' //1. useState is a Hook provided by React that allows you to add state management to functional components.
import {Link} from 'react-router-dom'
import {Button} from './Button'; // Adjust the path as necessary
import "./Navbar.css"



//2. npm install react-router-dom


function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);
  
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
  
    const showButton = () => {
      if (window.innerWidth <= 960) {
        setButton(false);
      } else {
        setButton(true);
      }
    };
  
    useEffect(() =>{
        showButton()
    }, [])

    window.addEventListener("resize", showButton)
   
  
    return (
      <>
        <nav className='navbar'>
          <div className='navbar-container'>
            <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
              Baruch Navigation
              
            </Link>
            <div className='menu-icon' onClick={handleClick}>
              <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
            </div>
            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
              
              <li className='nav-item'>
                <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                  Baruch Campus
                </Link>
              </li>
              
              <li className='nav-item'>
                <Link to='/FoodLocations' className='nav-links' onClick={closeMobileMenu}
                >Food Locations
                </Link>
              </li>
            
              

              <li className='nav-item'>
                <Link to='/Help' className='nav-links' onClick={closeMobileMenu}>
                  Help
                </Link>
              </li>
  
              
              
            </ul>
            {button && <Button buttonStyle='btn--outline'  linkTo='/contact'>Contact</Button>}
          </div>
        </nav>
      </>
    );
  }
  
  export default Navbar;