import React from 'react'
import "./Footer.css"

function Footer() {
  return (
    <div className="footer-container">
        
      
    </div>
  )
}

export default Footer
